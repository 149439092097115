.Toastify__progress-bar.Toastify__progress-bar--success {
  @apply bg-green-500;
  height: 1px;
}
.Toastify__progress-bar.Toastify__progress-bar--error {
  @apply bg-red-500;
  height: 1px;
}
.Toastify__toast-container .Toastify__toast-body {
  @apply text-gray-700;
  @apply text-xs;
}
.Toastify__toast-container .Toastify__toast-body h4 {
  @apply text-gray-900;
  @apply font-bold;
  @apply text-sm;
}
.Toastify__close-button {
  @apply bg-gray-800;
  @apply text-gray-100;
  @apply text-sm;
  @apply font-thin;
  @apply w-4;
  @apply h-4;
  @apply rounded-full;
  @apply leading-none;
}
.Toastify__toast--success {
  @apply bg-white;
  @apply shadow;
  @apply border-t-2;
  @apply border-green-500;
}
.Toastify__toast--error {
  @apply bg-white;
  @apply shadow;
  @apply border-t-2;
  @apply border-red-500;
}
.Toastify__toast {
  @apply rounded;
}
.Toastify__toast-body {
  @apply px-2;
  @apply text-sm;
}
