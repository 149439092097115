.branded-spinner {
  margin: 0 auto;
  text-align: center;
  width: 5rem;
  height: 5rem;
  position: relative;
  margin-top: 72px;
  margin-bottom: 108px;
}
.branded-spinner .logo {
  background-color: rgba(33, 175, 205, 0.75);
  background-image: url('/icon.png');
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50%;
}

.branded-spinner .loader {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  border: 1em solid;
  border-top-color: rgb(233, 56, 66);
  border-right-color: #f5f7fa;
  border-bottom-color: rgba(33, 175, 205, 0.75);
  border-left-color: #f5f7fa;
  z-index: 2;
  position: relative;
  -webkit-animation: 0.6s infinite loader ease-in-out;
  animation: 0.6s infinite loader ease-in-out;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
