@tailwind base;

@tailwind components;

@tailwind utilities;

a {
  @apply text-secondary;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

input:read-only {
  @apply bg-white;
}
input:read-only:focus {
  @apply bg-white;
  @apply outline-none;
  @apply shadow-none;
}
